<template>
  <v-container>
    <v-row class="Header" text-align="center" justify="center">
      <v-col cols="12">
        <v-img :src="require('../assets/BQ_Awards.png')" contain height="150" />
        <v-spacer></v-spacer>
        <h2 class="display-0 font-weight-bold mb-3">
          <h1>{{Title}}</h1>
        </h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-card class="Header"  elevation="24">
    </v-card>

    <v-card class="Header" >
        <template v-for="item in menu">
            <v-row align="center" :key="item.heading" justify="center" >
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <v-btn rounded block x-large color="#EDBC7B"  @click="RunForm(item)" >{{ item.Title }}</v-btn>
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>
        </template>
    </v-card>
  </v-container>
</template>

<script>
import Items from "../Data/Items";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      menu: Items,
      Title: "Events and Purchases",
    };
  },
  methods: {
    RunForm(item) {
      localStorage.setItem("Display_Form", JSON.stringify(item));
      this.$router.push(`/Display/`);
    }
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
h1, h2 {
    //background-color: black;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 15px;
    color: #830027;
    // color: orange;
}


</style>